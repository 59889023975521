import React from "react"
// import { CheckIcon } from '@heroicons/react/24/outline'
import { CheckIcon } from '@heroicons/react/solid'

const pricing = {
  tiers: [
    {
      title: 'Coupe (2 Doors)',
      price: 195,
    },
    {
      title: 'Sedan/Wagon (4 Doors)',
      price: 210,
    },
    {
      title: 'Small SUV (2 Rows)',
      price: 230,
    },
    {
      title: 'Large SUV (3 Rows)',
      price: 260,
    },
    {
      title: 'Small Truck (2 Doors)',
      price: 195,
    },
    {
      title: 'Large Truck (4 Doors) & Minivan',
      price: 230,
    },
  ],
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {
  return (
    <div className="mx-auto max-w-7xl bg-white py-24 px-4 sm:px-6 lg:px-8">
      <h2 className="text-center text-2xl font-bold tracking-tight text-gray-900 sm:text-5xl sm:leading-none lg:text-5xl">
        Interior Only or Exterior Only
      </h2>
      {/* Tiers */}
      <div className="text-center mt-6 sm:mt-24 gap-y-6 sm:grid lg:grid-cols-3 md:grid-cols-2 md:gap-x-4 lg:gap-x-6">
        {pricing.tiers.map((tier) => (
          <div
            key={tier.title}
            className="relative flex flex-col sm:rounded-2xl border border-gray-200 bg-white p-8 shadow-sm"
          >
            <div className="flex-1">
              <h3 className="text-xl font-semibold text-gray-900">{tier.title}</h3>
              <p className="mt-4 text-center items-baseline text-gray-900">
                <span className="text-5xl font-bold tracking-tight">${tier.price}</span>
              </p>


            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
